.detailedContentWrap {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
}

.detailedContentWrapActive {
  grid-template-rows: 1fr;
}

.detailedContent {
  overflow: hidden;
}
