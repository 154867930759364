.social_container {
    display: flex;
    flex-direction: column;
    padding-bottom: 200px;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        padding-bottom: 200px;
    }

    @media screen and (max-width: 1000px) {
        padding-bottom: 200px;
    }

    @media screen and (max-width: 470px) {
        padding-bottom: 60px;
    }
}

.social {
    display: flex;
    color: #000;
    font-size: 64px;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 40px;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        padding-bottom: 34px;
    }

    @media screen and (min-width: 470px) and (max-width: 1710px) {
        font-size: 46px;
    }

    @media screen and (max-width: 470px) {
        font-size: 24px;
    }
}

.social_block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        gap: 20px;
    }

    @media screen and (max-width: 530px) {
        flex-direction: column;
    }
}

.items_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        gap: 20px;
    }
}

.social_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 32px;
    background: #212121;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        width: 80px;
        height: 80px;
        border-radius: 30px;
    }
}