.underCharItemsWrap {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.underCharText {
  text-align: center;
  width: 100%;
}
