.invoiceTable {
  margin-top: 20px;
  padding: 0 20px;
}

.driverInvoicePagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.invoicesNotFound {
  display: flex;
  justify-content: center;
  font-size: 20px;
}
