@import "/src/styles/variables/index.scss";

.yellow_block_wrap {
  width: 100%;
  background-color: $yellow-700;
  padding: 28px 47px;
  border-radius: 35px;
  display: flex;
  margin: 65px 0 auto;
  justify-content: space-between;
  column-gap: 10px;

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  p {
    font-size: 32px;
    text-transform: uppercase;

    @media only screen and (max-width: $mobile-480) {
      font-size: 26px;
      text-align: center;
    }
  }

  @media only screen and (max-width: $laptop-1115) {
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: $mobile-480) {
    margin: 24px 0 auto;
  }
}

.yellow_block_text_bold {
  font-weight: 700;
}

.buttons_wrap {
  display: flex;
  column-gap: 25px;

  @media only screen and (max-width: $mobile-480) {
    flex-direction: column;
    row-gap: 20px;
  }
}
