@import "/src/styles/variables/index.scss";

.block_two_wrap {
  width: 100%;
  max-width: 1480px;
  margin: 74px auto 0;
  border: 1px solid $black-1000;
  padding: 50px 100px 90px;
  border-radius: 35px;

  h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  @media only screen and (max-width: $tab-870) {
    padding: 25px 25px 40px;
  }

  @media only screen and (max-width: $mobile-480) {
    margin: 24px 0 auto;
    h2 {
      font-size: 32px;
    }
  }
}

.block_two_second_text {
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;

  @media only screen and (max-width: $mobile-480) {
    font-size: 18px;
  }
}

.block_two_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  column-gap: 20px;
  row-gap: 80px;
  margin-top: 70px;

  li {
    width: 100%;
    max-width: calc(33.6% - 20px);
    min-width: 300px;
    display: flex;
    gap: 15px;
  }

  @media only screen and (max-width: $tab-870) {
    row-gap: 40px;
    column-gap: 10px;

    li {
      max-width: calc(50% - 10px);
    }
  }

  @media only screen and (max-width: $tab-700) {
    margin-top: 30px;

    li {
      max-width: 100%;
    }
  }

  @media only screen and (max-width: $mobile-480) {
    row-gap: 20px;

    li {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

.block_two_image_wrap {
  display: flex;
  background-color: $green-300;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  min-width: 65px;
  justify-content: center;
  align-items: center;

  img {
    display: flex;
    width: 35px;
    height: 35px;
  }
}

.block_two_inner_content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.block_two_inner_content_title {
  font-size: 21px;
  font-weight: 700;

  @media only screen and (max-width: $mobile-480) {
    font-size: 16px;
  }
}

.block_two_inner_content_description {
  font-size: 16px;
  font-weight: 400;

  @media only screen and (max-width: $mobile-480) {
    font-size: 14px;
  }
}
