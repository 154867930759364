.loader_container {
    display: flex;
    width: 100%;
    height: 100vh;
    background: white;
    padding: 0;
    align-items: center;
    justify-content: center;
}

.loader_block {
    position: relative;
}

.loader_ellipse {
    position: relative;
    display: flex;
    width: 88px;
    height: 88px;
    animation: rotateEllipsis 2s linear infinite;
}

.loader_line {
    position: absolute;
    top: 0;
    right: 0;
    width: 47px;
    height: 47px;
}

.loader_logo {
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes rotateEllipsis {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotateLine {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}