.contentWrap {
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 20px 0 0 10px;

  svg {
    width: 18px;
    height: 18px;
  }

  p {
    font-size: 16px;
    color: black;
    font-weight: 500;
    cursor: pointer;
  }
}
