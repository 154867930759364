@import "/src/styles/variables/index.scss";

.main_block {
  width: 100%;
  display: flex;
  border-radius: 10px;
  position: relative;
  margin-top: 50px;

  @media only screen and (max-width: $mobile-480) {
    margin-top: 24px;
  }
}

.main_image {
  display: flex;
  width: 100%;
  height: 100%;
}

.sk_share_buttons {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 170px;
  right: 270px;
  width: 100%;
  justify-content: flex-end;
  column-gap: 24px;

  a {
    display: flex;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: $laptop-1115) {
    right: 0;
    bottom: 10px;
    justify-content: center;
    margin: 0 auto;
  }

  @media only screen and (max-width: $mobile-480) {
    padding: 0 10px;
    column-gap: 5px;
    bottom: 2px;
  }
}
