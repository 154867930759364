.showDetailedButton {
  background-color: transparent;
  border: none;
  display: flex;
  margin: 0 auto;
  color: var(--inverted-bg-color);
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.showDetailedArrow {
  transition: all 0.3s linear;
  path {
    stroke: var(--inverted-bg-color);
  }

  &Active {
    transform: rotate(180deg);
  }
}
