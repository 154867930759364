@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");

:root {
  --font-family: "Inter", sans-serif;

  // Жирность шрифта
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  // Размер шрифта - xs
  --font-size-xs: 12px;

  // Размер шрифта - s
  --font-size-s: 14px;

  // Размер шрифта - m
  --font-size-m: 15px;

  // Размер шрифта - ml
  --font-size-ml: 16px;

  // Размер шрифта - l
  --font-size-l: 18px;

  // Размер шрифта - xl
  --font-size-xl: 20px;

  // Размер шрифта - sxl
  --font-size-sxl: 24px;

  // Размер шрифта - mlxl
  --font-size-mlxl: 30px;

  // Размер шрифта - msxl
  --font-size-msxl: 32px;

  // Размер шрифта - mxl
  --font-size-mxl: 36px;

  // Размер шрифта - lxl
  --font-size-lxl: 40px;

  // Размер шрифта - xxl
  --font-size-xxl: 48px;

  // Размер шрифта - xxxl
  --font-size-xxxl: 82px;
}

* {
  box-sizing: border-box;
}
