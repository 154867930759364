.peculiarities_container {
  display: flex;
  flex-direction: column;
  padding-bottom: 240px;

  @media screen and (min-width: 470px) and (max-width: 1000px) {
    padding-bottom: 96px;
  }

  @media screen and (max-width: 470px) {
    padding-bottom: 64px;
  }
}

.main_text {
  display: flex;
  width: 100%;
  font-size: 64px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  color: black;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 470px) and (max-width: 1710px) {
    font-size: 46px;
  }

  @media screen and (max-width: 470px) {
    font-size: 24px;
  }
}

.blocks_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 80px;

  @media screen and (max-width: 660px) {
    padding-top: 24px;
  }
}

.two_items {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  .first_item {
    display: flex;
    width: 50%;
    background: #212121;
    min-height: 440px;
    border-radius: 64px;
    align-items: flex-start;
    padding: 56px;
    position: relative;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
      min-height: 370px;
      padding: 50px;
    }

    @media screen and (max-width: 1000px) {
      width: auto;
    }

    @media screen and (min-width: 660px) and (max-width: 900px) {
      min-height: auto;
      height: 350px;
    }

    @media screen and (max-width: 660px) {
      padding: 16px;
      border-radius: 40px;
      min-height: auto;
      height: 370px !important;
    }

    @media screen and (max-width: 470px) {
      min-height: auto;
      height: 340px !important;
    }

    @media screen and (max-width: 370px) {
      min-height: auto;
      height: 300px !important;
    }

    @media screen and (max-width: 325px) {
      min-height: auto;
      height: 270px !important;
    }

    .image {
      position: absolute;
      bottom: 0;
      padding-right: 56px;

      @media screen and (max-width: 660px) {
        padding: 0;
        left: 0;
        right: 0;
      }

      @media screen and (max-width: 470px) {
        padding: 0;
        left: 0;
        right: 0;
        bottom: 10px;
      }

      @media screen and (max-width: 370px) {
        padding: 0;
        left: 0;
        right: 0;
        bottom: 20px;
      }

      img {
        width: 100%;
      }
    }

    .text_item {
      display: flex;
      align-items: center;
      z-index: 99999;

      @media screen and (min-width: 660px) and (max-width: 1310px) {
        align-items: flex-start;
      }

      @media screen and (min-width: 1000px) and (max-width: 1125px) {
        flex-direction: column !important;
      }

      @media screen and (max-width: 660px) {
        flex-direction: column !important;
        align-items: flex-start;
      }

      p {
        color: #fff;
        font-size: 48px;
        font-weight: 700;
        line-height: normal;
        padding-left: 24px;
        max-width: 540px;

        @media screen and (min-width: 660px) and (max-width: 1710px) {
          font-size: 37px;
          max-width: 420px;
        }

        @media screen and (min-width: 1000px) and (max-width: 1310px) {
          max-width: 300px;
        }

        @media screen and (min-width: 1000px) and (max-width: 1125px) {
          padding-left: 0;
          padding-top: 24px;
        }

        @media screen and (max-width: 660px) {
          font-size: 24px;
          max-width: none;
          padding: 12px 0;
        }
      }
    }

    .icon_block {
      display: flex;
      width: 96px;
      height: 96px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      background: #0782f3;

      @media screen and (min-width: 660px) and (max-width: 1710px) {
        width: 80px;
        height: 80px;

        img {
          height: 45px;
        }
      }

      @media screen and (max-width: 660px) {
        width: 56px;
        height: 56px;

        img {
          height: 30px;
        }
      }
    }
  }

  .second_item {
    display: flex;
    width: 50%;
    background: var(--theme-color);
    min-height: 440px;
    border-radius: 64px;
    align-items: flex-end;
    padding: 56px;
    position: relative;

    .padding {
      @media screen and (min-width: 750px) and (max-width: 790px) {
        margin-right: 15px !important;
      }
      @media screen and (min-width: 660px) and (max-width: 750px) {
        margin-right: 35px !important;
      }
    }

    .full {
      display: none;
    }

    .unfull {
      display: block;
    }

    @media screen and (min-width: 660px) and (max-width: 1710px) {
      min-height: 370px;
      padding: 50px;
    }

    @media screen and (max-width: 1000px) {
      width: auto;
    }

    @media screen and (min-width: 660px) and (max-width: 900px) {
      min-height: auto;
      height: 350px;
    }

    @media screen and (max-width: 660px) {
      .full {
        display: block;
      }

      .unfull {
        display: none;
      }
    }

    @media screen and (max-width: 660px) {
      padding: 16px;
      border-radius: 40px;
      align-items: flex-start;
    }

    @media screen and (max-width: 660px) {
      min-height: auto;
      height: 320px !important;
    }

    .image {
      position: absolute;
      bottom: 0;
      right: 0;

      @media screen and (min-width: 1000px) and (max-width: 1710px) {
        width: 59%;
        padding-right: 20px;
      }

      @media screen and (max-width: 1000px) {
        width: 51%;
        padding-right: 20px;
      }

      @media screen and (max-width: 790px) {
        padding-right: 90px;
      }

      @media screen and (max-width: 660px) {
        width: auto;
        padding-right: 10px;
      }

      img {
        width: 100%;
        bottom: 0;
        right: 0;

        @media screen and (max-width: 790px) {
          width: auto;
          height: 440px !important;
        }

        @media screen and (max-width: 660px) {
          width: auto;
          height: 227px !important;
        }
      }
    }

    .text_item {
      display: flex;
      flex-direction: column;
      z-index: 99999;

      p {
        color: #212121;
        font-size: 48px;
        font-weight: 700;
        line-height: normal;
        max-width: 470px;
        padding-top: 24px;

        @media screen and (min-width: 660px) and (max-width: 1710px) {
          font-size: 37px;
          max-width: 377px;
        }

        @media screen and (max-width: 660px) {
          font-size: 24px;
          max-width: none;
          padding: 12px 0;
        }
      }
    }

    .icon_block {
      display: flex;
      width: 96px;
      height: 96px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      background: #fff;

      @media screen and (min-width: 660px) and (max-width: 1710px) {
        width: 80px;
        height: 80px;

        img {
          height: 45px;
        }
      }

      @media screen and (max-width: 660px) {
        width: 56px;
        height: 56px;

        img {
          height: 30px;
        }
      }
    }
  }
}

.other_two_items {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
  padding-top: 24px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  .first_item {
    display: flex;
    width: 30%;
    background: #eee;
    min-height: 440px;
    border-radius: 64px;
    align-items: flex-end;
    padding: 56px;
    position: relative;

    @media screen and (min-width: 660px) and (max-width: 1710px) {
      min-height: 370px;
      padding: 50px;
    }

    @media screen and (max-width: 1000px) {
      width: auto;
    }

    @media screen and (max-width: 660px) {
      padding: 16px;
      border-radius: 40px;
      align-items: flex-end;
      padding-bottom: 30px;
    }

    @media screen and (max-width: 470px) {
      min-height: 380px;
    }

    @media screen and (max-width: 330px) {
      min-height: 340px;
    }

    .image {
      position: absolute;
      top: 60px;
      right: 50px;

      @media screen and (min-width: 1000px) and (max-width: 1710px) {
        width: 60%;
        top: 50px;
        right: 40px;
      }

      @media screen and (min-width: 660px) and (max-width: 800px) {
        width: 53%;
      }

      @media screen and (max-width: 660px) {
        bottom: 0;
        right: 10px;
        top: 30px;
      }

      img {
        width: 100%;
      }
    }

    .text_item {
      display: flex;
      flex-direction: column;
      z-index: 99999;

      p {
        color: #212121;
        font-size: 48px;
        font-weight: 700;
        line-height: normal;
        max-width: 300px;
        padding-top: 24px;

        @media screen and (min-width: 660px) and (max-width: 1710px) {
          font-size: 37px;
          max-width: 260px;
        }

        @media screen and (min-width: 660px) and (max-width: 800px) {
          font-size: 37px;
          max-width: none;
        }

        @media screen and (max-width: 660px) {
          font-size: 24px;
          max-width: none;
          padding: 12px 0;
        }
      }
    }

    .icon_block {
      display: flex;
      width: 96px;
      height: 96px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      background: var(--theme-color);

      @media screen and (min-width: 660px) and (max-width: 1710px) {
        width: 80px;
        height: 80px;

        img {
          height: 45px;
        }
      }

      @media screen and (max-width: 660px) {
        width: 56px;
        height: 56px;

        img {
          height: 30px;
        }
      }
    }
  }

  .second_item {
    display: flex;
    width: 70%;
    background: #0782f3;
    min-height: 440px;
    border-radius: 64px;
    align-items: flex-start;
    padding: 56px;
    position: relative;

    @media screen and (min-width: 660px) and (max-width: 1710px) {
      min-height: 370px;
      padding: 50px;
    }

    @media screen and (max-width: 1000px) {
      width: auto;
    }

    @media screen and (min-width: 660px) and (max-width: 900px) {
      min-height: auto;
      height: 350px;
    }

    @media screen and (max-width: 660px) {
      padding: 16px;
      border-radius: 40px;
      align-items: flex-start;
    }

    @media screen and (max-width: 660px) {
      min-height: auto;
      height: 380px;
    }

    .image {
      position: absolute;
      bottom: 0;
      right: 30px;

      @media screen and (min-width: 660px) and (max-width: 1710px) {
        width: 42%;
        right: 30px;
      }

      @media screen and (max-width: 660px) {
        width: auto;
        right: 10px;
      }

      img {
        width: 100%;

        @media screen and (max-width: 660px) {
          width: auto;
          height: 237px !important;
        }
      }
    }

    .text_item {
      display: flex;
      flex-direction: row;
      z-index: 99999;

      @media screen and (max-width: 660px) {
        flex-direction: column !important;
        align-items: flex-start;
      }

      p {
        color: #fff;
        font-size: 48px;
        font-weight: 700;
        line-height: normal;
        max-width: 470px;
        padding-left: 24px;

        @media screen and (min-width: 660px) and (max-width: 1710px) {
          font-size: 37px;
          max-width: 400px;
        }

        @media screen and (min-width: 660px) and (max-width: 1125px) {
          max-width: 360px;
        }

        @media screen and (max-width: 660px) {
          font-size: 24px;
          max-width: none;
          padding: 12px 0;
        }
      }
    }

    .icon_block {
      display: flex;
      width: 96px;
      height: 96px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      background: #212121;

      @media screen and (min-width: 660px) and (max-width: 1710px) {
        width: 80px;
        height: 80px;

        img {
          height: 45px;
        }
      }

      @media screen and (max-width: 660px) {
        width: 56px;
        height: 56px;

        img {
          height: 30px;
        }
      }
    }
  }
}
