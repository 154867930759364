.statisticWrap {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;

  h3 {
    font-size: 14px;
  }
}

.statisticMoney {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.statisticItemsWrap {
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;

  li {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-right: 1px solid var(--text-color-4);
    gap: 10px;

    &:last-child {
      border-right: none;
    }
  }
}

.statisticItemTitle {
  font-size: 18px;
  font-weight: 700;
}

.statisticItemDesc {
  font-size: 14px;
  white-space: nowrap;
}
