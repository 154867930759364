.detailsItemsWrap {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.detailsItem {
  margin-top: 20px;
}

.detailItemTitle {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.subItemsWrap {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;

  p {
    color: var(--text-color-2);
  }
}

.subItem {
  display: flex;
  justify-content: space-between;
}
