.description_container {
    display: flex;
    flex-direction: column;
    padding-bottom: 120px;

    @media screen and (min-width: 470px) and (max-width: 1000px) {
        padding-bottom: 150px;
    }

    @media screen and (max-width: 470px) {
        padding-bottom: 60px;
    }
}

.main_block {
    display: flex;
    position: relative;
    flex-direction: row;
    background: #0782F3;
    border-radius: 64px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 56px;
    padding-top: 110px;
    padding-bottom: 170px;

    @media screen and (min-width: 680px) and (max-width: 1225px) {
        border-radius: 64px;
        padding-bottom: 85px;
    }

    @media screen and (min-width: 470px) and (max-width: 769px) {
        padding: 56px 42px;
        padding-top: 110px;
        padding-bottom: 170px;
        min-height: auto;
    }

    @media screen and (max-width: 680px) {
        min-height: 800px;
        flex-direction: column;
        border-radius: 40px;
        min-height: auto;
    }

    @media screen and (max-width: 470px) {
        min-height: 600px;
        flex-direction: column;
        border-radius: 40px;
        padding: 16px;
        min-height: auto;
        padding-bottom: 50px;
    }
}

.icon_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.description_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    background: var(--theme-color);
    border-radius: 100%;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        width: 67px;
        height: 67px;

        img {
            width: 37px;
            height: 37px;
        }
    }

    @media screen and (min-width: 1000px) and (max-width: 1225px) {
        width: 57px;
        height: 57px;

        img {
            width: 27px;
            height: 27px;
        }
    }
}

.main_text_2 {
    color: #FFF;
    font-size: 64px;
    font-weight: var(--font-weight-bold);
    line-height: normal;
    letter-spacing: 0.1px;
    padding-top: 24px;
    max-width: 1000px;

    @media screen and (min-width: 830px) and (max-width: 1710px) {
        font-size: 46px;
        max-width: 800px;
    }

    @media screen and (min-width: 545px) and (max-width: 830px) {
        font-size: 46px;
    }

    @media screen and (min-width: 470px) and (max-width: 545px) {
        font-size: 36px;
    }

    @media screen and (max-width: 470px) {
        font-size: 24px;
    }
}