@import "/src/styles/variables/index.scss";

.main_title {
  margin: 65px 0 auto;
  font-size: 42px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;

  @media only screen and (max-width: $mobile-480) {
    margin: 24px 0 auto;
    font-size: 32px;
  }
}
