.advantages_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;

    @media screen and (max-width: 1000px) {
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 100px;
    }

    @media screen and (max-width: 470px) {
        padding-bottom: 40px;
    }
}

.main_text {
    display: flex;
    color: #000;
    font-size: 64px;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 40px;
    padding-bottom: 80px;
    text-align: center;
    max-width: 1500px;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        padding-bottom: 80px;
        max-width: 1090px;
    }

    @media screen and (min-width: 470px) and (max-width: 1710px) {
        font-size: 46px;
        padding-bottom: 64px;
    }

    @media screen and (max-width: 769px) {
        font-size: 48px;
    }

    @media screen and (max-width: 470px) {
        font-size: 24px;
        padding-bottom: 56px;
    }
}

.advantages_block {
    display: flex;
    gap: 100px;

    @media screen and (min-width: 1000px) and (max-width: 1225px) {
        gap: 50px;
    }

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        gap: 24px;
    }

    @media screen and (max-width: 470px) {
        gap: 40px;
    }
}

.main_item {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1225px) {
        justify-content: flex-start;
    }

    @media screen and (max-width: 470px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.text_align_first {
    color: #000;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    padding-left: 24px;
    max-width: 320px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        font-size: 19px;
        max-width: 270px;
    }

    @media screen and (min-width: 1000px) and (max-width: 1225px) {
        font-size: 15px;
        max-width: 200px;
    }

    @media screen and (max-width: 1000px) {
        max-width: none;
    }

    @media screen and (max-width: 635px) {
        max-width: 300px;
    }

    @media screen and (max-width: 470px) {
        max-width: none;
        padding-left: 0;
        padding-top: 24px;
    }
}

.text_align_second {
    color: #000;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    padding-left: 24px;
    max-width: 280px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        font-size: 19px;
        max-width: 210px;
    }

    @media screen and (min-width: 1000px) and (max-width: 1225px) {
        font-size: 15px;
        max-width: 170px;
    }

    @media screen and (max-width: 1000px) {
        max-width: none;
    }

    @media screen and (max-width: 635px) {
        max-width: 250px;
    }

    @media screen and (max-width: 470px) {
        max-width: none;
        padding-left: 0;
        padding-top: 24px;
    }
}

.text_align_third {
    color: #000;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    padding-left: 24px;
    max-width: 280px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        font-size: 19px;
        max-width: 280px;
    }

    @media screen and (min-width: 1000px) and (max-width: 1225px) {
        font-size: 15px;
        max-width: 220px;
    }

    @media screen and (max-width: 1000px) {
        max-width: none;
    }

    @media screen and (max-width: 635px) {
        max-width: 250px;
    }

    @media screen and (max-width: 470px) {
        max-width: none;
        padding-left: 0;
        padding-top: 24px;
    }
}

.advantages_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    background: var(--theme-color);
    border-radius: 100%;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        width: 67px;
        height: 67px;

        img {
            width: 37px;
            height: 37px;
        }
    }

    @media screen and (min-width: 1000px) and (max-width: 1225px) {
        width: 57px;
        height: 57px;

        img {
            width: 27px;
            height: 27px;
        }
    }
}

.download_icons {
    display: flex;
    padding-bottom: 92px;
    gap: 40px;
    align-items: center;
    justify-content: center;

    .full {
        display: none;
    }

    @media screen and (min-width: 1225px) and (max-width: 1710px) {
        align-items: center;
        justify-content: center;

        img {
            width: 140px;
        }
    }

    @media screen and (min-width: 1000px) and (max-width: 1225px) {
        align-items: center;
        justify-content: center;

        img {
            width: 140px;
        }
    }

    @media screen and (min-width: 555px) and (max-width: 1000px) {
        align-items: center;
        justify-content: center;

        .unfull {
            display: none;
        }

        .full {
            display: block;
        }
    }

    @media screen and (max-width: 555px) {
        flex-direction: column;
        padding-bottom: 60px;
        align-items: normal;
        gap: 24px;

        .unfull {
            display: none;
        }

        .full {
            display: block;
        }

        img {
            width: 100%;
        }
    }
}