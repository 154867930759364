// yellow
$yellow-700: #f5dc3d;

// black
$black-1000: #000000;
$black-990: #090909;

//green
$green-300: #2ae488;

// blue
$blue-300: #0088f0;

// white
$white-1000: #ffffff;
