* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}

.salaryModuleWrap {
  margin: 25px 16px 0px;
  background-color: var(--main-bg-color);
  border-radius: 16px;
  padding: 20px;
  color: var(--inverted-bg-color);
}
