@import "/src/styles/variables/index.scss";

.cookieBarWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $yellow-700;
  z-index: 9999;
}

.cookieBar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 40px;
  justify-content: space-between;

  p {
    font-weight: 700;
  }

  button {
    background-color: $black-990;
    color: $white-1000;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
  }
}
