.table_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 160px;
    padding-bottom: 160px;
}

@media screen and (min-width: 1225px) and (max-width: 1710px) {
    .table_container {
        padding-top: 140px;
        padding-bottom: 140px;
    }
}

@media screen and (max-width: 1225px) {
    .table_container {
        padding-top: 77px;
        padding-bottom: 77px;
    }
}

@media screen and (max-width: 470px) {
    .table_container {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

.main_text {
    font-size: var(--font-size-xxxl);
    color: black;
    font-weight: var(--font-weight-bold);
    line-height: normal;
    letter-spacing: 0.1px;
    padding-bottom: 28px;
}

/* Медиа-запросы для адаптивности текста */
@media screen and (min-width: 830px) and (max-width: 1710px) {
    .main_text {
        font-size: 60px;
        padding-bottom: 28px;
    }
}

@media screen and (min-width: 560px) and (max-width: 1225px) {
    .main_text {
        font-size: 60px;
        padding-bottom: 28px;
    }
}

@media screen and (max-width: 560px) {
    .main_text {
        font-size: 32px;
        padding-bottom: 24px;
    }
}

.table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 64px;
    border: 2px solid #000;

    @media screen and (max-width: 769px) {
        border-radius: 40px;
    }
}

.row {
    display: flex;
    justify-content: space-between;
    border: 1px solid #000;
}

.cell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(33.33% - 16px);
    height: 60px;
    padding: 8px;
    font-size: 24px;
    text-align: center;
    border-left: 2px solid #000;

    @media screen and (max-width: 1710px) {
        font-size: 20px;
    } 

    @media screen and (max-width: 400px) {
        font-size: 17px;
    } 

    @media screen and (max-width: 350px) {
        font-size: 16px;
    } 

    @media screen and (max-width: 330px) {
        font-size: 13px;
    }
}

.border_none_left {
    border-left: none;
}

.border_none_right {
    border-right: none;
}

.border_none_sides {
    border-left: none;
    border-right: none;
}

.border_none_top {
    border-top: none;
    font-weight: 700;
}

.border_none_bottom {
    border-bottom: none;
}

.tariffs_name {
    display: flex;
    color: #000;
    font-size: 40px;
    font-weight: 700;
    padding: 28px;
    line-height: normal;
    align-items: center;
    text-align: center;

    @media screen and (min-width: 1225px) and (max-width: 1710px) {
        font-size: 28px;
    }

    @media screen and (min-width: 1225px) and (max-width: 1435px) {
        font-size: 28px;
    }

    @media screen and (max-width: 1225px) {
        font-size: 28px;
    }

    @media screen and (max-width: 1000px) {
        font-size: 40px;
    }

    @media screen and (max-width: 560px) {
        font-size: 24px;
    }
}

.footnote {
    display: flex;
    padding-top: 28px;
    font-size: 18px;
}