.inspection_details_page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  gap: 12px;
  padding-top: 40px;

  p {
    margin: 0px !important;
  }
}

.inspections_main_block {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.main_driver_info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .driver_details_info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      object-fit: cover;
    }

    .main_info {
      display: flex;
      flex-direction: column;
      gap: 3px;

      p {
        margin: 0px !important;
      }

      .main_text {
        font-weight: 400;
        font-size: 14px;
        color: #9e9e9e;
      }

      .secondary_text {
        font-size: 16px;
        color: black;
        font-weight: 500;
        letter-spacing: 0.16px;
        text-decoration: underline;
      }
    }
  }
}

.inspections_data_title {
  color: black;
  font-size: 24px;
  font-weight: 700;
}

.data_title_span {
  padding-left: 5px;
  color: #9e9e9e;
}

.inspections_info_block {
  display: flex;
  padding-top: 5px;
  gap: 24px;

  img {
    width: 300px;
    height: 300px;
    border-radius: 16px;
    object-fit: cover;
    // border: 3px solid #1DB960;
  }

  .inspection_photo_none {
    display: flex;
    width: 300px;
    height: 300px;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: #eeeeee;

    .inspection_circle_none_photo {
      display: flex;
      width: 80px;
      height: 80px;
      align-items: center;
      justify-content: center;
      background: #fec600;
      border-radius: 100px;
    }
  }
}

.inspection_remark {
  display: flex;
  padding-top: 16px;
  align-items: center;
  justify-content: space-between;

  .inspection_add_remark {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      font-size: 14px;
      font-weight: 500;
      color: #0782f3;
      text-decoration: underline;
      padding-left: 4px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .inspection_updated_remark {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      font-size: 14px;
      font-weight: 500;
      color: #1db960;
      text-decoration: underline;
      padding-left: 4px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .inspection_edit_remark {
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      font-weight: 500;
      color: #ee392e;
      text-decoration: underline;
      padding-left: 4px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.inspections_info_grounds {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.inspections_info_ground {
  display: flex;
  width: 100%;
  gap: 20px;
}

.inspection_input {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  flex: 1;
  gap: 7px;

  p {
    color: black;
    font-size: 14px;
  }

  span {
    color: #ee392e;
  }

  input {
    display: flex;
    width: 100%;
    color: black;
    font-size: 14px;
    padding-left: 12px;
    height: 52px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    outline: none !important;

    &:focus,
    &:active {
      border: 1.5px solid #fec600 !important;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      outline: none !important;
    }
  }
}

.inspection_license_block {
  display: flex;
  padding-top: 5px;
  gap: 20px;

  .inspection_license_single_item {
    display: flex;
    width: 100%;
    flex-direction: column;

    img {
      width: 500px !important;
      height: 300px !important;
      border-radius: 16px;
      object-fit: cover;
      // border: 3px solid #1DB960;
    }

    .inspection_license_block_none {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 500px !important;
      height: 300px !important;
      border-radius: 16px;
      background: #eeeeee;

      .inspection_license_block_none_icon {
        display: flex;
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        background: #fec600;
        border-radius: 100px;

        svg {
          margin-top: 2px;
          margin-left: 2px;
        }
      }
    }
  }
}

.inspection_car_block {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 20px;
  gap: 20px;

  .inspection_car_single_item {
    display: flex;
    width: calc(33.33333% - 14px);
    cursor: pointer;

    img {
      width: 100%;
      max-height: 330px;
      border-radius: 16px;
      object-fit: cover;
      // border: 3px solid #1DB960;
    }
  }
}

.info-buttons {
  display: flex;
  padding-top: 20px;
  padding-bottom: 55px;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
  border-top: 1px solid #e0e0e0;

  button {
    outline: none !important;
  }

  .main-button {
    display: flex;
    height: 52px;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    border: 1px solid black;
    background: none;
    color: black;
    font-size: 16px;
    font-weight: 500;
    padding-left: 24px;
    padding-right: 24px;
    gap: 5px;

    path {
      stroke: black;
    }
  }

  .delete_btn {
    path {
      stroke: none !important;
      fill: black !important;
    }
  }

  .aprove-button {
    display: flex;
    height: 52px;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    background: #1db960;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    padding-left: 24px;
    padding-right: 24px;
    gap: 5px;

    path {
      stroke: white;
    }
  }
}

.border-buttons {
  display: flex;
  border-left: 1px solid #e0e0e0;
}

.old_inspection {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;

  .old_inspection_title {
    display: flex;
    padding: 20px 0 5px 0;
    cursor: pointer;

    p {
      font-size: 18px;
      color: black;
      font-weight: 800;
      margin: 0;
    }

    span {
      color: #9e9e9e;
      padding-left: 3px;
    }

    svg {
      margin-left: 10px;
    }
  }
}

.driver_inspection_block {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  gap: 20px;

  .driver_car_single_inspection_item {
    display: flex;
    width: calc(16.47% - 15px);
    cursor: pointer;

    img {
      width: 100%;
      max-height: 330px;
      border-radius: 16px;
      object-fit: cover;
    }
  }
}

.disabled_inputs {
  pointer-events: none;
}

.block_with_padding_top {
  padding-top: 30px;
}

.block_with_padding_bottom {
  padding-bottom: 30px;
}

.go_back_btn {
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 18px;
    height: 18px;
  }

  .go_back_text {
    font-size: 16px;
    color: black;
    font-weight: 500;
    cursor: pointer;
  }
}
