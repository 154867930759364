.bracket_container {
    display: flex;
    flex-direction: column;
    padding-top: 160px;
    padding-bottom: 120px;

    .full {
        display: none;
    }

    @media screen and (min-width: 1225px) and (max-width: 1710px) {
        padding-top: 140px;
    }

    @media screen and (max-width: 1225px) {
        padding-top: 77px;
        padding-bottom: 80px;
    }

    @media screen and (max-width: 769px) {
        .full {
            display: block;
        }
        .unfull {
            display: none;
        }
    }

    @media screen and (max-width: 470px) {
        padding-top: 25px;
        padding-bottom: 76px;

        .full {
            display: block;
        }
        .unfull {
            display: none;
        }
    }
}

.text_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
}

.main_text {
    font-size: var(--font-size-xxxl);
    color: black;
    max-width: 1200px;
    font-weight: var(--font-weight-bold);
    line-height: normal;
    padding-left: 50px;
    letter-spacing: 0.1px;
    padding-bottom: 70px;
    max-width: 900px;

    @media screen and (min-width: 830px) and (max-width: 1710px) {
        font-size: 60px;
        max-width: 680px;
        padding-bottom: 28px;
    }

    @media screen and (min-width: 470px) and (max-width: 1225px) {
        font-size: 60px;
        max-width: none;
        padding-left: 0;
        padding-bottom: 64px;
    }

    @media screen and (max-width: 1000px) {
        padding-bottom: 0;
    }

    @media screen and (max-width: 470px) {
        font-size: 32px;
        max-width: none;
        padding-left: 0;
        padding-bottom: 0;
    }
}

.second_text {
    color: #212121;
    font-size: 32px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
    padding-right: 50px;
    max-width: 700px;
    padding-top: 25px;
    padding-left: 20px;

    @media screen and (min-width: 830px) and (max-width: 1710px) {
        font-size: 24px;
        padding-top: 18px;
        max-width: 530px;
        padding-bottom: 28px;
    }

    @media screen and (min-width: 769px) and (max-width: 1225px) {
        max-width: 450px;
        padding-right: 0;
        padding-bottom: 28px;
    }

    @media screen and (min-width: 470px) and (max-width: 1000px) {
        font-size: 32px;
        max-width: none;
        padding-left: 0;
        padding-right: 0;
        padding-top: 24px;
        padding-bottom: 48px;
    }

    @media screen and (max-width: 470px) {
        font-size: 20px;
        max-width: none;
        padding-left: 0;
        padding-right: 0;
        padding-top: 24px;
        padding-bottom: 30px;
    }
}

.main_block {
    display: flex;

    img {
        width: 100%;
    }
}