.header_container {
    display: flex;
    flex-direction: column;
    padding-top: 160px;
    padding-bottom: 180px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        padding-top: 140px;
        padding-bottom: 180px;
    }

    @media screen and (max-width: 1000px) {
        padding-top: 77px;
        padding-bottom: 56px;
    }

    @media screen and (max-width: 769px) {
        padding-top: 50px;
        padding-bottom: 56px;
    }

    @media screen and (max-width: 470px) {
        padding-top: 25px;
        padding-bottom: 40px;
    }
}

.main_photo {
    position: relative;
    display: flex;
    width: 100%;

    .first_photo {
        display: block;
    }

    .second_photo {
        display: none;
    }

    .third_photo {
        display: none;
    }

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        .first_photo {
            display: block;
        }

        .second_photo {
            display: none;
        }
    
        .third_photo {
            display: none;
        }
    }

    @media screen and (min-width: 660px) and (max-width: 1000px) {
        .first_photo {
            display: none;
        }

        .second_photo {
            display: block;
        }
    
        .third_photo {
            display: none;
        }
    }

    @media screen and (max-width: 660px) {

        .first_photo {
            display: none;
        }

        .second_photo {
            display: none;
        }
    
        .third_photo {
            display: block;
        }
    }

    img {
        width: 100%;
    }
}

.main_text {
    position: absolute;
    font-size: var(--font-size-xxxl);
    color: #FFF;
    font-weight: var(--font-weight-bold);
    line-height: normal;
    letter-spacing: 0.1px;
    padding: 89px 53px;
    max-width: 760px;

    @media screen and (min-width: 830px) and (max-width: 1710px) {
        font-size: 60px;
        padding: 89px 53px;
        max-width: 570px;
    }

    @media screen and (min-width: 615px) and (max-width: 830px) {
        font-size: 60px;
        padding: 40px 30px;
        max-width: 570px;
    }

    @media screen and (max-width: 615px) {
        font-size: 32px;
        padding: 25px 25px;
        max-width: 570px;
    }

    @media screen and (max-width: 400px) {
        font-size: 32px;
        padding: 22px 17px;
        max-width: 570px;
    }
}