.partnership_container {
    display: flex;
    padding-bottom: 240px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        padding-bottom: 240px;
    }

    @media screen and (max-width: 1000px) {
        padding-bottom: 96px;
    }

    @media screen and (max-width: 530px) {
        padding-bottom: 64px;
    }
}

.partnership_block {
    display: flex;
    width: 100%;
    min-height: 645px;
    background: var(--theme-color);
    border-radius: 64px;
    align-items: center;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media screen and (max-width: 530px) {
        border-radius: 40px;
    }
}

.main_text {
    font-size: 82px;
    max-width: 850px;
    padding-bottom: 60px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.1px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        font-size: 62px;
        max-width: 650px;
    }

    @media screen and (min-width: 1000px) and (max-width: 1225px) {
        font-size: 52px;
        max-width: 650px;
    }

    @media screen and (min-width: 660px) and (max-width: 1000px) {
        font-size: 60px;
        max-width: 650px;
        padding-top: 50px;
        padding-right: 50px;
    }

    @media screen and (max-width: 660px) {
        font-size: 50px;
        max-width: 650px;
        padding-top: 50px;
        padding-right: 30px;
    }

    @media screen and (max-width: 530px) {
        font-size: 32px;
        max-width: 650px;
        padding-top: 40px;
        padding-right: 0;
        padding-bottom: 30px;
    }
}

.main_btn {
    display: flex;
    width: 300px;
    height: 74px;
    padding: 2px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #212121;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        font-size: 62px;
        max-width: 650px;
        width: 230px;
        height: 60px;
    }

    @media screen and (max-width: 530px) {
        width: 100%;
        max-width: none;
        height: 74px;
        letter-spacing: 1px;
    }
}

.item_block {
    padding-left: 60px;

    @media screen and (min-width: 660px) and (max-width: 1000px) {
        padding-left: 50px;
    }

    @media screen and (max-width: 660px) {
        padding-left: 30px;
    }

    @media screen and (max-width: 530px) {
        padding-left: 16px;
        padding-right: 16px;
    }

    a {
        text-decoration: none;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.6px;
        text-transform: uppercase;

        @media screen and (min-width: 1000px) and (max-width: 1710px) {
            font-size: 15px;
        }

        @media screen and (max-width: 530px) {
            letter-spacing: 1px;
            font-weight: 700;
        }
    }
}

.text_block {
    display: flex;
    flex-direction: column;
    padding-right: 60px;
    gap: 53px;
    padding-left: 100px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        gap: 44px;
    }

    @media screen and (min-width: 660px) and (max-width: 1000px) {
        padding-left: 50px;
        padding-top: 100px;
        padding-bottom: 60px;
    }

    @media screen and (max-width: 660px) {
        padding-left: 30px;
        padding-top: 100px;
        padding-bottom: 60px;
    }

    @media screen and (max-width: 530px) {
        padding-left: 16px;
        padding-top: 45px;
        padding-bottom: 45px;
        padding-right: 16px;
        text-align: center;
        align-items: center;
        gap: 25px;
    }
}

.block {
    display: flex;

    @media screen and (max-width: 530px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .icon {
        @media screen and (min-width: 1000px) and (max-width: 1710px) {
            img {
                height: 24px;
            }
        }
    }
}

.text {
    display: flex;
    flex-direction: column;
    padding-left: 24px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        padding-left: 20px;
    }

    @media screen and (max-width: 530px) {
        align-items: center;
        padding-left: 0;
    }
}

.first_text {
    color: #212121;
    font-size: 32px;
    font-weight: 700;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        font-size: 25px;
        font-weight: 700;
    }

    @media screen and (max-width: 530px) {
        font-size: 20px;
        padding-top: 24px;
    }
}

.second_text {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    padding-top: 10px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        font-size: 17px;
        line-height: normal;
    }

    @media screen and (max-width: 530px) {
        font-size: 20px;
        line-height: normal;
    }
}