.article_container {
    display: flex;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 160px;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        padding-bottom: 56px;
        padding-left: 0;
        padding-right: 0;
    }

    @media screen and (min-width: 769px) and (max-width: 830px) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 56px;
    }

    @media screen and (max-width: 769px) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 56px;
    }
}

.text_block {
    display: flex;
    flex-direction: column;
    padding-right: 30px;

    @media screen and (max-width: 1000px) {
        padding-right: 0;
    }
}

.main_text {
    font-size: 48px;
    color: black;
    max-width: 1200px;
    font-weight: var(--font-weight-bold);
    line-height: normal;
    letter-spacing: 0.1px;
    max-width: 870px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        font-size: 37px;
        max-width: 670px;
    }

    @media screen and (max-width: 615px) {
        font-size: 24px;
    }
}

.second_text {
    color: #212121;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    padding-top: 24px;
    max-width: 700px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        font-size: 19px;
        max-width: 560px;
    }

    @media screen and (max-width: 1000px) {
        padding-bottom: 56px;
    }

    @media screen and (max-width: 615px) {
        font-size: 24px;
    }
}

.yellow_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--theme-color);
    border-radius: 64px;
    padding-top: 64px;
    padding-bottom: 64px;
    min-width: 600px;

    @media screen and (min-width: 1140px) and (max-width: 1710px) {
        padding-top: 54px;
        padding-bottom: 54px;
        min-width: 500px;
    }

    @media screen and (min-width: 1000px) and (max-width: 1140px) {
        padding-top: 54px;
        padding-bottom: 54px;
        min-width: 340px;
    }

    @media screen and (max-width: 1000px) {
        min-width: auto;
        width: 100%;
    }

    @media screen and (max-width: 615px) {
        padding-top: 24px;
        padding-bottom: 40px;
        border-radius: 40px;
    }

    img {
        @media screen and (min-width: 1000px) and (max-width: 1710px) {
            width: 200px;
        }

        @media screen and (max-width: 470px) {
            width: 100%;
        }
    }

    a {
        @media screen and (max-width: 470px) {
            width: calc(100% - 32px);
        }
    }
}

.text_format {
    color: #212121;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 40px;

    @media screen and (min-width: 1140px) and (max-width: 1710px) {
        font-size: 28px;
        padding-bottom: 30px;
    }

    @media screen and (min-width: 1000px) and (max-width: 1140px) {
        font-size: 28px;
        padding-bottom: 30px;
        max-width: 190px;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 615px) {
        font-size: 24px;
    }

    @media screen and (max-width: 470px) {
        max-width: 200px;
    }
}