[data-theme="yellow"] {
    --theme-color: #FFDE17;

    --main-bg-color: #2c2d31;
    --inverted-bg-color: #ffffff;
  
    --default-bg-input: #eeeeee;
  
    --order-label-sum: #4169e1;
    --order-text-sum: #212121;
}