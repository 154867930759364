.starter_container {
  display: flex;
  flex-direction: column;
  padding-top: 160px;

  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    padding-top: 140px;
  }

  @media screen and (max-width: 1225px) {
    padding-top: 77px;
  }

  @media screen and (max-width: 470px) {
    padding-top: 25px;
  }
}

.main_text {
  font-size: var(--font-size-xxxl);
  color: black;
  max-width: 1200px;
  font-weight: var(--font-weight-bold);
  line-height: normal;
  padding-left: 50px;
  letter-spacing: 0.1px;
  padding-bottom: 28px;

  @media screen and (min-width: 830px) and (max-width: 1710px) {
    font-size: 60px;
    max-width: 900px;
    padding-bottom: 28px;
  }

  @media screen and (min-width: 470px) and (max-width: 1225px) {
    font-size: 60px;
    max-width: none;
    padding-left: 0;
    padding-bottom: 64px;
  }

  @media screen and (max-width: 470px) {
    font-size: 32px;
    max-width: none;
    padding-left: 0;
    padding-bottom: 24px;
  }
}

.main_block {
  display: flex;
  position: relative;
  flex-direction: row;
  min-height: 871px;
  background: #0782f3;
  border-radius: 64px;
  justify-content: space-between;
  padding-left: 114px;

  @media screen and (min-width: 1370px) and (max-width: 1710px) {
    padding-left: 100px;
    min-height: 700px;
  }

  @media screen and (min-width: 1225px) and (max-width: 1370px) {
    padding-left: 100px;
    min-height: 500px;
  }

  @media screen and (min-width: 800px) and (max-width: 1225px) {
    padding-left: 40px;
    min-height: 790px;
    border-radius: 40px;
  }

  @media screen and (min-width: 680px) and (max-width: 800px) {
    padding-left: 40px;
    min-height: 778px;
    border-radius: 40px;
  }

  @media screen and (max-width: 680px) {
    padding-left: 30px;
    min-height: 800px;
    flex-direction: column;
    border-radius: 40px;
  }

  @media screen and (max-width: 470px) {
    padding-left: 25px;
    min-height: 600px;
    flex-direction: column;
    border-radius: 40px;
  }
}

.for_links {
  display: flex;
  align-items: center;

  @media screen and (min-width: 680px) and (max-width: 1225px) {
    padding-top: 40px;
    align-items: flex-start;
  }

  @media screen and (max-width: 680px) {
    padding-top: 30px;
    align-items: flex-start;
  }
}

.links {
  display: flex;
  flex-direction: column;
}

.main_text_2 {
  color: #fff;
  font-size: 64px;
  font-weight: var(--font-weight-bold);
  line-height: normal;
  letter-spacing: 0.1px;

  @media screen and (min-width: 830px) and (max-width: 1710px) {
    font-size: 46px;
  }

  @media screen and (min-width: 545px) and (max-width: 830px) {
    font-size: 50px;
    padding-right: 20px;
  }

  @media screen and (min-width: 470px) and (max-width: 545px) {
    font-size: 36px;
  }

  @media screen and (max-width: 470px) {
    font-size: 24px;
    padding-right: 20px;
  }
}

.main_text_3 {
  color: var(--white-day, #fff);
  font-size: 40px;
  font-weight: var(--font-weight-regular);
  line-height: normal;
  letter-spacing: 0.1px;
  padding-top: 5px;
  max-width: 700px;

  @media screen and (min-width: 830px) and (max-width: 1710px) {
    font-size: 29px;
    max-width: 480px;
  }

  @media screen and (min-width: 545px) and (max-width: 830px) {
    font-size: 35px;
    max-width: 550px;
    padding-top: 10px;
    padding-right: 20px;
  }

  @media screen and (min-width: 470px) and (max-width: 545px) {
    font-size: 27px;
    max-width: 400px;
    padding-top: 10px;
  }

  @media screen and (max-width: 470px) {
    font-size: 20px;
    padding-right: 25px;
    padding-top: 10px;
  }
}

.donwoload_links {
  display: flex;
  padding-top: 50px;
  gap: 40px;

  .full_link {
    display: none;
  }

  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    padding-top: 30px;
    gap: 30px;

    svg {
      width: 140px;
    }
  }

  @media screen and (min-width: 680px) and (max-width: 1225px) {
    flex-direction: column;
  }

  @media screen and (min-width: 470px) and (max-width: 680px) {
    flex-direction: row;
    gap: 26px;
  }

  @media screen and (max-width: 470px) {
    flex-direction: column;
    padding-top: 5px;
    gap: 10px;
    padding-right: 25px;

    svg {
      width: 100%;
      height: auto;
    }

    .unfull_link {
      display: none;
    }

    .full_link {
      display: flex;
    }
  }
}

.mobile_screens {
  display: flex;
  padding-right: 100px;
  padding-left: 20px;
  padding-top: 50px;
  align-items: flex-end;

  .full_mobiles {
    display: none;
  }

  @media screen and (max-width: 1720px) {
    img {
      height: 700px;
    }
  }

  @media screen and (max-width: 1370px) {
    img {
      height: 500px;
    }
  }

  @media screen and (max-width: 1225px) {
    img {
      height: 570px;
    }

    position: absolute;
    padding-right: 0;
    bottom: 0;
    right: 40px;
  }

  @media screen and (min-width: 769px) and (max-width: 830px) {
    img {
      height: 470px;
    }

    position: absolute;
    padding-right: 0;
    bottom: 0;
    right: 40px;
  }

  @media screen and (min-width: 680px) and (max-width: 769px) {
    img {
      height: 540px;
    }

    position: absolute;
    padding-right: 0;
    bottom: 0;
    right: 10px;

    .unfull_mobiles {
      display: none;
    }

    .full_mobiles {
      display: block;
    }
  }

  @media screen and (max-width: 680px) {
    img {
      width: 100%;
      height: 100%;
      max-width: 680px;
    }

    position: relative;
    padding-right: 20px;
    padding-top: 50px;
    padding-left: 0;
    bottom: 0;
    right: 0;

    .unfull_mobiles {
      display: none;
    }

    .full_mobiles {
      display: block;
    }
  }

  @media screen and (max-width: 470px) {
    img {
      width: 100%;
      height: 100%;
      max-width: 680px;
    }

    position: relative;
    padding-right: 20px;
    padding-top: 18px;
    padding-left: 0;
    bottom: 0;
    right: 0;

    .unfull_mobiles {
      display: none;
    }

    .full_mobiles {
      display: block;
    }
  }
}
