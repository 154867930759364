@import "/src/styles/variables/index.scss";

.block_three_wrap {
  width: 100%;
  max-width: 1480px;
  margin: 74px auto 0;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(
    auto-fill,
    minmax(400px, 1fr)
  ); /* Adjust the minmax values as needed */
  row-gap: 60px; /* Adjust the gap between items as needed */
  column-gap: 60px; /* Adjust the gap between items as needed */

  @media only screen and (max-width: $mobile-480) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin: 24px 0 auto;
  }
}

.block_three_item {
  width: 100%;
  height: 446px;
  background-color: $blue-300;
  padding: 35px;
  border-radius: 35px;
  color: $white-1000;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  h4 {
    font-size: 30px;
    font-weight: 700;
  }

  &:nth-child(odd) {
    background-color: $green-300;
    color: $black-1000;
  }
}

.block_three_item_desc,
.block_three_inner_item {
  margin-top: 50px;
  font-size: 24px;
  font-weight: 500;
}

.block_three_inner_item {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
