.invite_container {
    display: flex;
    flex-direction: column;
    padding-top: 53px;
    padding-bottom: 120px;

    .links_relative {
        display: none;
    }

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        padding-top: 53px;
        padding-bottom: 120px;
    }

    @media screen and (max-width: 1000px) {
        padding-top: 77px;
        padding-bottom: 80px;
    }

    @media screen and (max-width: 769px) {
        padding-top: 50px;
        padding-bottom: 80px;
    }

    @media screen and (max-width: 600px) {
        .links_relative {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 16px;
            padding-bottom: 0;
            gap: 24px;

            a {
                width: calc(100% - 52px);
            }

            img {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 470px) {
        padding-top: 25px;
        padding-bottom: 54px;
    }
}

.main_photo {
    position: relative;
    display: flex;
    width: 100%;

    .first_photo {
        display: block;
    }

    .second_photo {
        display: none;
    }

    .third_photo {
        display: none;
    }

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        .first_photo {
            display: block;
        }

        .second_photo {
            display: none;
        }
    
        .third_photo {
            display: none;
        }
    }

    @media screen and (min-width: 660px) and (max-width: 1000px) {
        .first_photo {
            display: none;
        }

        .second_photo {
            display: block;
        }
    
        .third_photo {
            display: none;
        }
    }

    @media screen and (max-width: 660px) {

        .first_photo {
            display: none;
        }

        .second_photo {
            display: none;
        }
    
        .third_photo {
            display: block;
        }
    }

    img {
        width: 100%;
    }
}

.absolute_content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.main_text {
    font-size: var(--font-size-xxxl);
    color: #FFF;
    font-weight: var(--font-weight-bold);
    line-height: normal;
    letter-spacing: 0.1px;
    padding: 89px 53px;
    padding-bottom: 50px;
    max-width: 830px;

    @media screen and (min-width: 830px) and (max-width: 1710px) {
        font-size: 60px;
        padding: 89px 53px;
        padding-bottom: 40px;
        max-width: 670px;
    }

    @media screen and (min-width: 600px) and (max-width: 830px) {
        font-size: 60px;
        padding: 40px 30px;
        padding-top: 55px;
        padding-bottom: 40px;
        max-width: none;
    }

    @media screen and (max-width: 600px) {
        font-size: 32px;
        padding: 25px 25px;
        max-width: none;
    }

    @media screen and (max-width: 400px) {
        font-size: 32px;
        padding: 22px 17px;
        max-width: none;
    }
}

.links_absolute {
    display: flex;
    gap: 40px;
    padding: 0 53px;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {

        img {
            width: 195px;
        }
    }

    @media screen and (min-width: 660px) and (max-width: 1000px) {
        flex-direction: column;
    }

    @media screen and (max-width: 830px) {
        flex-direction: column;
        padding-left: 30px;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

